import ReactDOM from 'react-dom'
import 'core-js/modules/es6.set'; // react-dom
import 'core-js/modules/es6.map'; // react-dom
// import 'smoothscroll-polyfill';
import React, { Fragment } from "react"
import { ThemeProvider } from 'styled-components'
import { ScrollToProvider } from '@zauberware/react-scroll-to'
import { IconProvider } from '@zauberware/react-svg-assets'
import { I18nextProvider } from 'react-i18next'

import icons from './src/images/icons'
import theme from './src/styled/theme'
import GlobalStyle from './src/styled/global-styles'
import i18n from './src/i18n'

export const wrapRootElement = ({ element }) => {
  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <IconProvider icons={icons}>
          <ScrollToProvider>
            <Fragment>
              {element}
              <GlobalStyle />
            </Fragment>
          </ScrollToProvider>
        </IconProvider>
      </ThemeProvider>
    </I18nextProvider>
  )
}

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    console.log("rendering!");
    ReactDOM.render(element, container, callback);
  };
};