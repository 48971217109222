import React, { Fragment } from 'react'
import { css, createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'

import fonts from './fonts/fonts.css'
import slick from './slick.css'

const GlobalStyle = createGlobalStyle(props => css`
  ${styledNormalize}
  ${fonts}
  ${slick}
  body {
    font-size: 14px;
    font-family: ${props.theme.fonts.sans};
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-weight: 400;
    overflow-x: hidden;
    width: 100%;
  }

  ul.slick-dots {
    bottom: 5px;
    
    li { 
      button {
        width: 12px;
        height: 12px;
        background-color: rgba(255,255,255,0.5);
        border-radius: 50%;
        :before {
          content: '';
        }
      }
      &.slick-active button {
        background-color: rgba(255,255,255,1);
      }
    }
  }
`)
export default () => (
  <Fragment>
    <GlobalStyle />
  </Fragment>
)
