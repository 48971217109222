import i18n from 'i18next'
import XHR from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { reactI18nextModule } from 'react-i18next'

export const options = {
  debug: false,
  lng: 'de',
  fallbackLng: 'de',
  nsSeparator: ':::',
  keySeparator: '::',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  whitelist: ['de', 'en'],
  react: {
    wait: true,
  },
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
  },
}

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init(options)

export default i18n
