
const primary = '#fff1d0'             // pale-beige
const secondary = '#3c25f2'           // blue
const strongBlue = '#240aed'          // link-color
const lightGray = '#e6e6e6'           // light-gray
const darkGray = '#595959'            // dark-gray
const fontColor = '#2c2c2c'           // lighter black
const fontColorSecondary = '#0003ff'  // headline-blue

const fontPrimary = 'EuclidCircular'
const fontSecondary = 'SuisseIntl'

const fontSizes = [
  12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 65, 72, 110, 136, 130
]

const space = [
  0, 4, 8, 16, 24, 32, 64, 96, 128, 206, 256
]

export default {
  //breakpoints: ['40em', '52em', '64em'],
  breakpoints: ['768px', '1200px', '1920px'],
  fontSizes: fontSizes,
  colors: {
    primary: primary,
    secondary: secondary,
    lightGray: lightGray,
    darkGray: darkGray,
    font: fontColor,
    fontSecondary: fontColorSecondary,
    hover: strongBlue,
  },
  space: space,
  fonts: {
    sans: fontPrimary + ', sans',
    secondary: fontSecondary + ', sans-serif',
    fallback: '"Work Sans", system-ui, sans-serif',
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)'
  },
  headings: {
    hero: {
      fontWeight: 500,
      letterSpacing: 'normal',
      padding: 0,
      margin: 0,
    },
    landing: {
      fontSize: fontSizes[6],
      fontWeight: 400,
      marginTop: space[4],
      marginBottom: 0,
      letterSpacing: 'normal',
    },
    contact: {
      fontWeight: 500,
      margin: 0,
      padding: 0,
    },
    testimonial: {
      fontFamily: fontSecondary,
      fontSize: fontSizes[6],
      fontWeight: 400,
      lineHeight: '57px',
      color: '#fff',
      margin: 0,
      padding: 0,
    },
    slider: {
      fontWeight: 400,
      margin: 0,
    },
    competence: {
      fontWeight: 500,
      margin: 0,
      padding: 0,
      paddingTop: space[3],
    },
    h1: {
      fontWeight: 500,
      margin: 0,
      padding: 0,
      paddingBottom: space[4],
      textAlign: 'center',
    },
    h2: {
      color: fontColorSecondary,
      fontWeight: 400,
      margin: 0,
      padding: 0,
      paddingTop: space[3],
      paddingBottom: space[3],
      textAlign: 'center',
    },
    h3: {
      fontWeight: 400,
      marginTop: space[4],
      marginBottom: space[4],
      letterSpacing: 'normal',
    },
    h4: {
      fontSize: fontSizes[2],
      fontWeight: 500,
      marginTop: space[4],
      marginBottom: space[2],
      color: fontColor,
      letterSpacing: 'normal',
    },
    h5: {
      fontFamily: fontSecondary,
      fontWeight: 400,
      color: 'white',
      letterSpacing: 'normal',
    }
  },
  text: {
    headline: {
      fontFamily: fontSecondary,
      fontWeight: 500,
    },
    running: {
      fontFamily: fontSecondary,
      fontWeight: 350,
    },
    form: {
      fontFamily: fontSecondary,
      fontWeight: 500,
    }
  },
  buttons: {
    primary: {
      fontFamily: fontSecondary,
      fontWeight: 500,
      color: '#fff',
      backgroundColor: secondary,
      border: '1px solid ' + secondary,
      '&:hover': {
        color: secondary,
        backgroundColor: '#fff',
        border: '1px solid ' + secondary,
      }
    },
    secondary: {
      fontFamily: fontSecondary,
      color: secondary,
      backgroundColor: '#fff',
      boxShadow: 'inset 0 0 0 2px',
      '&:hover': {
        color: '#fff',
        backgroundColor: secondary
      },
      '&:active, &:focus': {
        outline: 'none'
      }
    },
    form: {
      color: '#fff',
      backgroundColor: lightGray,
      border: '1px solid ' + lightGray,
      cursor: 'not-allowed',
    },
    block: {
      color: '#fff',
      backgroundColor: secondary,
      boxShadow: 'inset 0 0 0 2px',
      borderRadius: 0,
      border: 0,
      boxShadow: 0,
      border: '1px solid ' + secondary,
      '&:hover': {
        color: secondary,
        backgroundColor: '#fff',
        border: '1px solid ' + secondary,
      }
    },
    menu: {
      fontFamily: fontSecondary,
      fontWeight: 500,
      color: '#fff',
      backgroundColor: secondary,
      border: '1px solid #fff',
      '&:hover': {
        color: secondary,
        backgroundColor: '#fff',
        border: '1px solid ' + secondary,
      }
    }
  },
  icons: {
    sizes: ['16px', '24px', '32px', '48px', '96px', '128px', '256px']
  }
}