import AssetWortmarke from './wortmarke.svg'
import IconCaret from './icons/caret.svg'
import IconLogo from './logo.svg'
import IconLogoToggled from './logo-white.svg' 

import IconShirt from './icons/shirt-buttons.svg'
import IconBox from './icons/box.svg'
import IconClock from './icons/clock.svg'
import IconDeliveryFast from './icons/delivery-fast.svg'
import IconDistance from './icons/distance.svg'
import IconHandshake from './icons/handshake.svg'
import IconHeart from './icons/heart.svg'
import IconShopLocation from './icons/shop-location.svg'
import IconStorageUnit from './icons/storage-unit.svg'
import IconWorld from './icons/world.svg'

import IconFeature from './icons/icons-feature.svg'
import IconSafety from './icons/icons-safety.svg'
import IconPartner from './icons/icons-partner.svg'

import IconPhone from './icons/phone.svg'
import IconMail from './icons/mail.svg'
import IconFax from './icons/fax.svg'

export default (_icon) => {
  const icons = {
    'shirt'         : IconShirt,
    'fax'           : IconFax,
    'feature'       : IconFeature,
    'safety'        : IconSafety,
    'partner'       : IconPartner,
    'mail'          : IconMail,
    'phone'         : IconPhone,
    'wortmarke'     : AssetWortmarke,
    'logo'          : IconLogo,
    'logoToggled'   : IconLogoToggled,
    'box'           : IconBox,
    'clock'         : IconClock,
    'delivery-fast' : IconDeliveryFast,
    'distance'      : IconDistance,
    'handshake'     : IconHandshake,
    'heart'         : IconHeart,
    'shop-location' : IconShopLocation,
    'storage-unit'  : IconStorageUnit,
    'world'         : IconWorld,
    'caret'         : IconCaret,
  }
  return _icon ? icons[_icon] : icons
}
